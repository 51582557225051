import {Dashboards, DateUtil} from "xf-common";

const today = new Date();
const todayString = DateUtil.dateObjectToFennecDBString(new Date());
const firstDayOfMonth = DateUtil.dateObjectToFennecDBString(new Date(today.getFullYear(), today.getMonth(), 1));
const lastDayOfMonth = DateUtil.dateObjectToFennecDBString(new Date(today.getFullYear(), today.getMonth() + 1, 0));

const avgTruthyCalc = function(values: any, _data: any, calcParams: any) {
  let sum = 0;
  let count = 0;
  values.forEach((v: number) => {
    if(v != null) {
      sum += v;
      count++;
    }
  });
  return sum / count;
};

export const DASHBOARDS_JSON: Dashboards = {
  claimStatus: {
    name: "Claim Status",
    dashboardContext: ["STANDARD"],
    sheetName: "Claim Status Report",
    xlsxSheetName: "Claim Status Report",
    xlsxSheetFileName: "claim-status-report.xlsx",
    csvFileName: "claim-status-report.csv",
    endpointSuffix: "db1",
    columns: [
      {
        title: "Stay ID",
        field: "caseId",
        sorter: "number",
        width: 100,
        visible: true,
        hozAlign: "right"
      },
      {
        title: "Type",
        field: "caseType",
        sorter: "string",
        width: 200,
        visible: true
      },
      {
        title: "Plan",
        field: "healthPlanName",
        sorter: "string",
        width: 200,
        visible: true
      },
      {
        title: "Client Claim Id",
        field: "clientClaimId",
        sorter: "string",
        width: 100,
        editable: false,
        visible: true
      },
      {
        title: "Patient Name",
        field: "patientName",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Patient Alt Id",
        field: "patientAltId",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Case Stage",
        field: "caseStage",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Stage (Main) Status",
        field: "stageStatus",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "CF Status",
        field: "customerFacingStatusLabel",
        sorter: "string",
        width: 300,
        editable: false,
        visible: true
      },
      {
        title: "DOS Start",
        field: "coverPeriodFromDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "DOS End",
        field: "coverPeriodThruDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Provider Name",
        field: "primaryProviderName",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "IB Received",
        field: "ibReceivedDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Claim Due Date",
        field: "claimDueDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Review Sent to Client",
        field: "reviewReportSentToClientDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Review Sent to Provider",
        field: "reviewReportSentToProvider",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 200,
        editable: false,
        visible: true
      }
    ],
    filters: [
      {
        label: "Select Client(s)",
        dashboardFilterParamType: "PRIMARY_CLIENTS",
        dataType: "primary_client",
        formControlName: "primaryClient",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Plan(s)",
        dashboardFilterParamType: "HEALTH_CARE_PLANS",
        dataType: "health_care_plan",
        formControlName: "healthCarePlan",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Line of Business",
        dashboardFilterParamType: "LOB",
        dataType: "lob",
        formControlName: "lob",
        defaultValue: "",
        required: true
      },
      {
        label: "Claim Create From Date",
        dashboardFilterParamType: "FROM_DATE",
        dataType: "date",
        formControlName: "fromDate",
        defaultValue: firstDayOfMonth,
        required: true
      },
      {
        label: "Claim Create Thru Date",
        dashboardFilterParamType: "THRU_DATE",
        dataType: "date",
        formControlName: "thruDate",
        defaultValue: lastDayOfMonth,
        required: true
      },
      {
        label: "Select Case Type(s)",
        dashboardFilterParamType: "MI_CASE_TYPES",
        dataType: "mi_case_type",
        formControlName: "miCaseType",
        defaultValue: "",
        required: true
      },
    ]
  },
  savingsReport: {
    name: "Savings",
    dashboardContext: ["STANDARD"],
    sheetName: "Savings Report",
    xlsxSheetName: "Savings Report",
    xlsxSheetFileName: "savings-report.xlsx",
    csvFileName: "savings-report.csv",
    endpointSuffix: "db2",
    columns: [
      {
        title: "Stay ID",
        field: "caseId",
        sorter: "number",
        width: 100,
        visible: true,
        hozAlign: "right"
      },
      {
        title: "Type",
        field: "caseType",
        sorter: "string",
        width: 200,
        visible: true
      },
      {
        title: "Plan",
        field: "healthPlanName",
        sorter: "string",
        width: 200,
        visible: true
      },
      {
        title: "Client Claim No",
        field: "clientClaimNo",
        sorter: "string",
        width: 100,
        editable: false,
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Patient Name",
        field: "patientName",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Patient Alt Id",
        field: "altPatientId",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "DOS Start",
        field: "coverPeriodFrom",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "DOS End",
        field: "coverPeriodTo",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Provider Name",
        field: "primaryProviderName",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Complete Date",
        field: "reviewReportSentToClientDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Billed Amount",
        field: "billedAmount",
        width: 120,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Savings Amount",
        field: "savingsAmount",
        width: 120,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Pre 6DH Review Payable",
        field: "preReviewPayable",
        width: 120,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Post 6DH Review Payable",
        field: "postReviewPayable",
        width: 120,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "True Savings",
        field: "trueSavings",
        width: 120,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Savings Percent",
        field: "savingsPercent",
        width: 120,
        editable: false,
        formatter: "money",
        bottomCalc: avgTruthyCalc,
        bottomCalcFormatterParams: { symbol: '%', symbolAfter: true },
        bottomCalcFormatter: "money",
        formatterParams: { symbol: '%', symbolAfter: true },
        hozAlign: "right",
        headerWordWrap: true,
        visible: true
      }
    ],
    filters: [
      {
        label: "Select Client(s)",
        dashboardFilterParamType: "PRIMARY_CLIENTS",
        dataType: "primary_client",
        formControlName: "primaryClient",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Plan(s)",
        dashboardFilterParamType: "HEALTH_CARE_PLANS",
        dataType: "health_care_plan",
        formControlName: "healthCarePlan",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Line of Business",
        dashboardFilterParamType: "LOB",
        dataType: "lob",
        formControlName: "lob",
        defaultValue: "",
        required: true
      },
      {
        label: "Claim Create From Date",
        dashboardFilterParamType: "FROM_DATE",
        dataType: "date",
        formControlName: "fromDate",
        defaultValue: firstDayOfMonth,
        required: true
      },
      {
        label: "Claim Create Thru Date",
        dashboardFilterParamType: "THRU_DATE",
        dataType: "date",
        formControlName: "thruDate",
        defaultValue: lastDayOfMonth,
        required: true
      },
      {
        label: "Select Case Type(s)",
        dashboardFilterParamType: "MI_CASE_TYPES",
        dataType: "mi_case_type",
        formControlName: "miCaseType",
        defaultValue: "",
        required: true
      }
    ]
  },
  providerActivityReport: {
    name: "Provider Activity",
    dashboardContext: ["STANDARD"],
    sheetName: "Provider Activity Report",
    xlsxSheetName: "Provider Activity Report",
    xlsxSheetFileName: "provider-activity-report.xlsx",
    csvFileName: "provider-activity-report.csv",
    endpointSuffix: "db3",
    columns: [
      {
        title: "Stay ID",
        field: "caseId",
        sorter: "number",
        width: 100,
        visible: true,
        hozAlign: "right"
      },
      {
        title: "Plan",
        field: "healthPlanName",
        sorter: "string",
        width: 200,
        visible: true
      },
      {
        title: "Provider Name",
        field: "primaryProviderName",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Provider EIN",
        field: "providerEIN",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Top Adj Expl",
        field: "topAdjustmentExplanationCode",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Top Adj Expl Desc",
        field: "topAdjustmentExplanationDesc",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Top Adj Expl Total",
        field: "topAdjustmentExplanationTotal",
        sorter: "string",
        width: 200,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        visible: true
      },
      {
        title: "Provider City",
        field: "providerCity",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Provider State",
        field: "providerState",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Total Claim Amount",
        field: "totalClaimAmount",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Total Adjustment",
        field: "totalAdjustment",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Total Savings",
        field: "totalSavings",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Average Savings",
        field: "averageSavings",
        width: 100,
        editable: false,
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Average Savings %",
        field: "averageSavingsPercent",
        width: 100,
        editable: false,
        formatter: "money",
        formatterParams: { symbol: '%', symbolAfter: true },
        hozAlign: "right",
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Claim Type Count",
        field: "claimTypeCount",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        hozAlign: "right",
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Claim Type %",
        field: "claimTypePercent",
        width: 100,
        editable: false,
        formatter: "money",
        bottomCalc: avgTruthyCalc,
        formatterParams: { symbol: '%', symbolAfter: true },
        hozAlign: "right",
        headerWordWrap: true,
        visible: false
      },
      {
        title: "IB Received %",
        field: "ibReceivedPercent",
        width: 100,
        editable: false,
        formatter: "money",
        bottomCalc: avgTruthyCalc,
        formatterParams: { symbol: '%', symbolAfter: true },
        hozAlign: "right",
        headerWordWrap: true,
        visible: false
      },
      {
        title: "Avg Days Recvd IB",
        field: "averageDaysRecieveIB",
        width: 100,
        editable: false,
        hozAlign: "right",
        headerWordWrap: true,
        visible: false
      },
      {
        title: "Conversion Rate",
        field: "conversionRate",
        width: 100,
        editable: false,
        hozAlign: "right",
        headerWordWrap: true,
        visible: false
      },
      {
        title: "UpHeld Rate %",
        field: "upheldRatePercent",
        width: 100,
        editable: false,
        formatter: "money",
        bottomCalc: avgTruthyCalc,
        bottomCalcFormatterParams: { symbol: '%', symbolAfter: true },
        bottomCalcFormatter: "money",
        formatterParams: { symbol: '%', symbolAfter: true },
        hozAlign: "right",
        headerWordWrap: true,
        visible: false
      }
    ],
    filters: [
      {
        label: "Select Client(s)",
        dashboardFilterParamType: "PRIMARY_CLIENTS",
        dataType: "primary_client",
        formControlName: "primaryClient",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Plan(s)",
        dashboardFilterParamType: "HEALTH_CARE_PLANS",
        dataType: "health_care_plan",
        formControlName: "healthCarePlan",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Line of Business",
        dashboardFilterParamType: "LOB",
        dataType: "lob",
        formControlName: "lob",
        defaultValue: "",
        required: true
      },
      {
        label: "Claim Create From Date",
        dashboardFilterParamType: "FROM_DATE",
        dataType: "date",
        formControlName: "fromDate",
        defaultValue: firstDayOfMonth,
        required: true
      },
      {
        label: "Claim Create Thru Date",
        dashboardFilterParamType: "THRU_DATE",
        dataType: "date",
        formControlName: "thruDate",
        defaultValue: lastDayOfMonth,
        required: true
      },
      {
        label: "Select Case Type(s)",
        dashboardFilterParamType: "MI_CASE_TYPES",
        dataType: "mi_case_type",
        formControlName: "miCaseType",
        defaultValue: "",
        required: true
      }
    ]
  },
  attachmentActivityReport: {
    name: "Attachment Activity",
    dashboardContext: ["STANDARD"],
    sheetName: "Attachment Activity Report",
    xlsxSheetName: "Attachment Activity Report",
    xlsxSheetFileName: "attachment-activity-report.xlsx",
    csvFileName: "attachment-activity-report.csv",
    endpointSuffix: "db4",
    columns: [
      {
        title: "Stay ID",
        field: "caseId",
        sorter: "number",
        width: 100,
        visible: true,
        hozAlign: "right"
      },
      {
        title: "Type",
        field: "caseType",
        sorter: "string",
        width: 200,
        visible: true
      },
      {
        title: "Case Name",
        field: "caseName",
        sorter: "string",
        width: 300,
        visible: true
      },
      {
        title: "Plan Name",
        field: "hcpName",
        sorter: "string",
        width: 300,
        visible: true
      },
      {
        title: "Att Name",
        field: "attachmentName",
        sorter: "string",
        width: 300,
        visible: true
      },
      {
        title: "Att Type",
        field: "attachmentType",
        sorter: "string",
        width: 200,
        visible: true
      },
      {
        title: "Created",
        field: "attachmentCreatedDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 100,
        visible: true
      },
      {
        title: "Created By",
        field: "attachmentCreatedBy",
        sorter: "string",
        width: 300,
        visible: true
      },
    ],
    filters: [
      {
        label: "Select Client(s)",
        dashboardFilterParamType: "PRIMARY_CLIENTS",
        dataType: "primary_client",
        formControlName: "primaryClient",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Plan(s)",
        dashboardFilterParamType: "HEALTH_CARE_PLANS",
        dataType: "health_care_plan",
        formControlName: "healthCarePlan",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Line of Business",
        dashboardFilterParamType: "LOB",
        dataType: "lob",
        formControlName: "lob",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Attachment Types(s)",
        dashboardFilterParamType: "ATTACHMENT_TYPES",
        dataType: "attachment_type",
        formControlName: "attachmentType",
        defaultValue: "",
        required: true
      },
      {
        label: "From Date",
        dashboardFilterParamType: "FROM_DATE",
        dataType: "date",
        formControlName: "fromDate",
        defaultValue: todayString,
        required: true
      },
      {
        label: "Thru Date",
        dashboardFilterParamType: "THRU_DATE",
        dataType: "date",
        formControlName: "thruDate",
        defaultValue: todayString,
        required: true
      },
      {
        label: "Select Case Type(s)",
        dashboardFilterParamType: "MI_CASE_TYPES",
        dataType: "mi_case_type",
        formControlName: "miCaseType",
        defaultValue: "",
        required: true
      }
    ]
  },
  centeneClaimsInProgress: {
    name: "Claims In Process",
    dashboardContext: ["CENTENE"],
    sheetName: "Claims In Process",
    xlsxSheetName: "Claims In Process",
    xlsxSheetFileName: "claims-in-process.xlsx",
    csvFileName: "claims-in-process.csv",
    endpointSuffix: "centene-db1",
    columns: [
      {
        title: "Stay ID",
        field: "caseId",
        sorter: "number",
        width: 100,
        visible: true,
        hozAlign: "right"
      },
      {
        title: "Plan",
        field: "healthPlanName",
        sorter: "string",
        width: 200,
        visible: true
      },
      {
        title: "Client Claim Id",
        field: "clientClaimId",
        sorter: "string",
        width: 100,
        editable: false,
        visible: true,
        headerWordWrap: true,
        // frozen: true
      },
      {
        title: "Client Member No",
        field: "clientMemberNumber",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Patient Name",
        field: "patientName",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
        // frozen: true
      },
      {
        title: "Patient Alt Id",
        field: "patientAltId",
        sorter: "string",
        width: 200,
        editable: false,
        visible: false
      },
      {
        title: "Line of Business",
        field: "lineOfBusiness",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "DOS Start",
        field: "coverPeriodFromDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "DOS End",
        field: "coverPeriodThruDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Provider Name",
        field: "primaryProviderName",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Provider EIN",
        field: "primaryProviderEIN",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "NPI Number",
        field: "npiNumber",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Provider Par",
        field: "providerPar",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Referral Date",
        field: "referralDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "IB Received",
        field: "ibReceivedDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "TAT",
        field: "daysInProcess",
        sorter: "number",
        width: 100,
        editable: false,
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Claim Due Date",
        field: "claimDueDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 160,
        editable: false,
        visible: true
      },
      {
        title: "Review Sent to Client",
        field: "reviewReportSentToClientDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Review Sent to Provider",
        field: "reviewReportSentToProvider",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Total Billed Chgs",
        field: "billedAmount",
        sorter: "string",
        width: 200,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        visible: true
      },
      {
        title: "Outlier Payable",
        field: "outlierPayable",
        sorter: "string",
        width: 200,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        editable: false,
        visible: true
      },
      {
        title: "DRG Code",
        field: "drgCode",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "DRG Code Desc",
        field: "drgCodeDescription",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "DRG Paid Date",
        field: "drgPaidDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Claim Stage",
        field: "caseStage",
        sorter: "string",
        width: 200,
        editable: false,
        visible: false
      },
      {
        title: "Stage (Main) Status",
        field: "stageStatus",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "CF Status",
        field: "customerFacingStatusLabel",
        sorter: "string",
        width: 300,
        editable: false,
        visible: true
      },
      {
        title: "Appeal Received",
        field: "appealReceivedDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Appeal Response Sent",
        field: "appealResponseSentToProviderDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Post Payment Review",
        field: "postPaymentReview",
        formatter: "tickCross",
        width: 80,
        editable: false,
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Total Claim Savings",
        field: "totalClaimSavings",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: false
      },
      {
        title: "Total Savings by Expl",
        field: "totalSavingsByExplanations",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: false
      },
    ],
    filters: [
      {
        label: "Select Client(s)",
        dashboardFilterParamType: "PRIMARY_CLIENTS",
        dataType: "primary_client",
        formControlName: "primaryClient",
        defaultValue: firstDayOfMonth,
        required: true
      },
      {
        label: "Select Plan(s)",
        dashboardFilterParamType: "HEALTH_CARE_PLANS",
        dataType: "health_care_plan",
        formControlName: "healthCarePlan",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Line of Business",
        dashboardFilterParamType: "LOB",
        dataType: "lob",
        formControlName: "lob",
        defaultValue: "",
        required: true
      },
      {
        label: "Claim Create From Date",
        dashboardFilterParamType: "FROM_DATE",
        dataType: "date",
        formControlName: "fromDate",
        defaultValue: firstDayOfMonth,
        required: true
      },
      {
        label: "Claim Create Thru Date",
        dashboardFilterParamType: "THRU_DATE",
        dataType: "date",
        formControlName: "thruDate",
        defaultValue: lastDayOfMonth,
        required: true
      }
    ],
  },
  centeneSavingsReport: {
    name: "Savings Report",
    dashboardContext: ["CENTENE"],
    sheetName: "Savings Report",
    xlsxSheetName: "Savings Report",
    xlsxSheetFileName: "savings-report.xlsx",
    csvFileName: "savings-report.csv",
    endpointSuffix: "centene-db2",
    columns: [
      {
        title: "Stay ID",
        field: "caseId",
        sorter: "number",
        width: 100,
        visible: true,
        hozAlign: "right"
      },
      {
        title: "Plan",
        field: "healthPlanName",
        sorter: "string",
        width: 200,
        visible: true
      },
      {
        title: "Patient Name",
        field: "patientName",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Patient DOB",
        field: "patientDOB",
        sorter: "string",
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Client Member No",
        field: "clientMemberNumber",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "DOS Start",
        field: "coverPeriodFromDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "DOS End",
        field: "coverPeriodThruDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Health Plan Name",
        field: "healthPlanName",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Provider Name",
        field: "primaryProviderName",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Provider EIN",
        field: "providerEIN",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Complete Date",
        field: "reviewReportSentToClientDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Total Billed Chgs",
        field: "totalBilledCharges",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Pre 6DH Review Payable",
        field: "preReviewPayable",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Post 6DH Review Payable",
        field: "postReviewPayable",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "True Savings",
        field: "trueSavings",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Savings Percent",
        field: "savingsPercent",
        sorter: "string",
        width: 100,
        editable: false,
        formatter: "money",
        bottomCalc: avgTruthyCalc,
        bottomCalcFormatterParams: { symbol: '%', symbolAfter: true },
        bottomCalcFormatter: "money",
        formatterParams: { symbol: '%', symbolAfter: true },
        hozAlign: "right",
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Conversion Rate",
        field: "conversionRate",
        sorter: "string",
        width: 100,
        editable: false,
        hozAlign: "right",
        headerWordWrap: true,
        visible: false
      },
      {
        title: "Upheld Rate",
        field: "upheldRate",
        sorter: "string",
        width: 100,
        editable: false,
        hozAlign: "right",
        headerWordWrap: true,
        visible: false
      },
    ],
    filters: [
      {
        label: "Select Client(s)",
        dashboardFilterParamType: "PRIMARY_CLIENTS",
        dataType: "primary_client",
        formControlName: "primaryClient",
        defaultValue: firstDayOfMonth,
        required: true
      },
      {
        label: "Select Plan(s)",
        dashboardFilterParamType: "HEALTH_CARE_PLANS",
        dataType: "health_care_plan",
        formControlName: "healthCarePlan",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Line of Business",
        dashboardFilterParamType: "LOB",
        dataType: "lob",
        formControlName: "lob",
        defaultValue: "",
        required: true
      },
      {
        label: "Claim Create From Date",
        dashboardFilterParamType: "FROM_DATE",
        dataType: "date",
        formControlName: "fromDate",
        defaultValue: firstDayOfMonth,
        required: true
      },
      {
        label: "Claim Create Thru Date",
        dashboardFilterParamType: "THRU_DATE",
        dataType: "date",
        formControlName: "thruDate",
        defaultValue: lastDayOfMonth,
        required: true
      }
    ]
  },
  savingsByReasonAndExplanation: {
    name: "Savings Breakdown",
    dashboardContext: ["CENTENE"],
    sheetName: "Savings Breakdown",
    xlsxSheetName: "Savings Breakdown",
    xlsxSheetFileName: "savings-breakdown.xlsx",
    csvFileName: "savings-breakdown.csv",
    endpointSuffix: "savings-reason-expl",
    columns: [
      {
        title: "Stay ID",
        field: "caseId",
        sorter: "number",
        width: 100,
        visible: true,
        hozAlign: "right"
      },
      {
        title: "Plan",
        field: "healthPlanName",
        sorter: "string",
        width: 200,
        visible: true
      },
      {
        title: "Client Claim Id",
        field: "clientClaimId",
        sorter: "string",
        width: 100,
        editable: false,
        visible: true
      },
      {
        title: "Client Member No",
        field: "clientMemberNumber",
        sorter: "string",
        width: 200,
        editable: false,
        visible: false
      },
      {
        title: "Provider Name",
        field: "primaryProviderName",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Provider EIN",
        field: "providerEIN",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Patient Name",
        field: "patientName",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Patient DOB",
        field: "patientDOB",
        sorter: "string",
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "DOS Start",
        field: "coverPeriodFromDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "DOS End",
        field: "coverPeriodThruDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Total Billed Chgs",
        field: "totalBilledCharges",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Pre 6DH Review Payable",
        field: "preReviewPayable",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Post 6DH Review Payable",
        field: "postReviewPayable",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "True Savings",
        field: "trueSavings",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Savings Percent",
        field: "savingsPercent",
        sorter: "string",
        width: 100,
        editable: false,
        formatter: "money",
        bottomCalc: avgTruthyCalc,
        bottomCalcFormatterParams: { symbol: '%', symbolAfter: true },
        bottomCalcFormatter: "money",
        formatterParams: { symbol: '%', symbolAfter: true },
        hozAlign: "right",
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Client Internal Adjustments",
        field: "clientInternalAdjustments",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
    ],
    filters: [
      {
        label: "Select Client(s)",
        dashboardFilterParamType: "PRIMARY_CLIENTS",
        dataType: "primary_client",
        formControlName: "primaryClient",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Plan(s)",
        dashboardFilterParamType: "HEALTH_CARE_PLANS",
        dataType: "health_care_plan",
        formControlName: "healthCarePlan",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Line of Business",
        dashboardFilterParamType: "LOB",
        dataType: "lob",
        formControlName: "lob",
        defaultValue: "",
        required: true
      },
      {
        label: "Claim Create From Date",
        dashboardFilterParamType: "FROM_DATE",
        dataType: "date",
        formControlName: "fromDate",
        defaultValue: firstDayOfMonth,
        required: true
      },
      {
        label: "Claim Create Thru Date",
        dashboardFilterParamType: "THRU_DATE",
        dataType: "date",
        formControlName: "thruDate",
        defaultValue: lastDayOfMonth,
        required: true
      }
    ]
  },
  publishedReviewsReport: {
    name: "Published Reviews",
    dashboardContext: ["STANDARD", "CENTENE"],
    sheetName: "Published Reviews Report",
    xlsxSheetName: "Published Reviews Report",
    xlsxSheetFileName: "published-reviews-report.xlsx",
    csvFileName: "published-reviews-report.csv",
    endpointSuffix: "db5",
    columns: [
      {
        title: "Stay ID",
        field: "caseId",
        sorter: "number",
        width: 90,
        visible: true,
        hozAlign: "right"
      },
      {
        title: "Att ID",
        field: "attachmentId",
        sorter: "number",
        width: 90,
        visible: true,
        hozAlign: "right"
      },
      {
        title: "Att Type",
        field: "attachmentType",
        sorter: "number",
        width: 90,
        visible: true,
        hozAlign: "right"
      },
      {
        title: "Publish Date",
        field: "attachmentPublishDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Review Type",
        field: "itemizedRevisionReviewType",
        sorter: "string",
        width: 130,
        visible: true,
        hozAlign: "left"
      },
      {
        title: "Name/Patient",
        field: "caseName",
        sorter: "string",
        width: 300,
        visible: true,
        hozAlign: "left"
      },
      {
        title: "Created By",
        field: "createdBy",
        sorter: "string",
        width: 200,
        visible: true,
        hozAlign: "left"
      },
      {
        title: "Plan",
        field: "hcpName",
        sorter: "string",
        width: 300,
        visible: true,
        hozAlign: "left"
      },
      {
        title: "Line of Business",
        field: "lineOfBusiness",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Client Claim Id",
        field: "clientClaimId",
        sorter: "string",
        width: 150,
        visible: true,
        hozAlign: "left"
      },
      {
        title: "Provider Name",
        field: "primaryProviderName",
        sorter: "string",
        width: 300,
        editable: false,
        visible: true
      },
      {
        title: "Status",
        field: "miCaseMainStatusLabel",
        sorter: "string",
        width: 150,
        editable: false,
        visible: false
      },
      {
        title: "CF Status",
        field: "customerFacingStatusLabel",
        sorter: "string",
        width: 300,
        editable: false,
        visible: false
      },
      {
        title: "DOS Start",
        field: "coverPeriodFromDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "DOS End",
        field: "coverPeriodThruDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Referral Date",
        field: "referralDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "IB Received",
        field: "ibReceivedDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Complete Date",
        field: "reviewReportSentToClientDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Total Billed Chgs",
        field: "totalBilledCharges",
        sorter: "string",
        width: 150,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
    ],
    filters: [
      {
        label: "Select Client(s)",
        dashboardFilterParamType: "PRIMARY_CLIENTS",
        dataType: "primary_client",
        formControlName: "primaryClient",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Plan(s)",
        dashboardFilterParamType: "HEALTH_CARE_PLANS",
        dataType: "health_care_plan",
        formControlName: "healthCarePlan",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Line of Business",
        dashboardFilterParamType: "LOB",
        dataType: "lob",
        formControlName: "lob",
        defaultValue: "",
        required: true
      },
      {
        label: "From Date",
        dashboardFilterParamType: "FROM_DATE",
        dataType: "date",
        formControlName: "fromDate",
        defaultValue: todayString,
        required: true
      },
      {
        label: "Thru Date",
        dashboardFilterParamType: "THRU_DATE",
        dataType: "date",
        formControlName: "thruDate",
        defaultValue: todayString,
        required: true
      }
    ]
  },
  dailyReferralReport: {
    name: "Daily Referral",
    dashboardContext: ["STANDARD"],
    sheetName: "Daily Referral Report",
    xlsxSheetName: "Daily Referral Report",
    xlsxSheetFileName: "daily-referral-report.xlsx",
    csvFileName: "daily-referral-report.csv",
    endpointSuffix: "db6",
    columns: [
      {
        title: "Stay ID",
        field: "caseId",
        sorter: "number",
        width: 90,
        visible: true,
        hozAlign: "right"
      },
      {
        title: "Type",
        field: "caseType",
        sorter: "string",
        width: 200,
        visible: true
      },
      {
        title: "Plan",
        field: "hcpName",
        sorter: "string",
        width: 300,
        visible: true,
        hozAlign: "left"
      },
      {
        title: "Name/Patient",
        field: "caseName",
        sorter: "string",
        width: 300,
        visible: true,
        hozAlign: "left"
      },
      {
        title: "Line of Business",
        field: "lineOfBusiness",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "DOS Start",
        field: "coverPeriodFromDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "DOS End",
        field: "coverPeriodThruDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Provider Name",
        field: "primaryProviderName",
        sorter: "string",
        width: 300,
        editable: false,
        visible: true
      },
      {
        title: "Referral Date",
        field: "referralDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "CF Status",
        field: "customerFacingStatusLabel",
        sorter: "string",
        width: 300,
        editable: false,
        visible: false
      },
      {
        title: "Total Billed Chgs",
        field: "totalBilledCharges",
        sorter: "string",
        width: 150,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Nurse Reviewer",
        field: "lineItemAdjUserId",
        sorter: "string",
        width: 300,
        editable: false,
        visible: true
      },
    ],
    filters: [
      {
        label: "Select Client(s)",
        dashboardFilterParamType: "PRIMARY_CLIENTS",
        dataType: "primary_client",
        formControlName: "primaryClient",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Plan(s)",
        dashboardFilterParamType: "HEALTH_CARE_PLANS",
        dataType: "health_care_plan",
        formControlName: "healthCarePlan",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Line of Business",
        dashboardFilterParamType: "LOB",
        dataType: "lob",
        formControlName: "lob",
        defaultValue: "",
        required: true
      },
      {
        label: "From Date",
        dashboardFilterParamType: "FROM_DATE",
        dataType: "date",
        formControlName: "fromDate",
        defaultValue: todayString,
        required: true
      },
      {
        label: "Thru Date",
        dashboardFilterParamType: "THRU_DATE",
        dataType: "date",
        formControlName: "thruDate",
        defaultValue: todayString,
        required: true
      },
      {
        label: "Select Case Type(s)",
        dashboardFilterParamType: "MI_CASE_TYPES",
        dataType: "mi_case_type",
        formControlName: "miCaseType",
        defaultValue: "",
        required: true
      },
      {
        label: "Nurse Reviewer",
        dashboardFilterParamType: "MI_CASE_USERS_LINE_ITEM_ADJ",
        dataType: "line_adj_user",
        formControlName: "nurseReviewer",
        defaultValue: "ALL",
        required: true
      }
    ]
  },
  appealTrackerReport: {
    name: "Appeal Monthly Tracker",
    dashboardContext: ["STANDARD", "CENTENE"],
    sheetName: "Appeal Monthly Tracker Report",
    xlsxSheetName: "Appeal Monthly Tracker Report",
    xlsxSheetFileName: "appeal-monthly-tracker-report.xlsx",
    csvFileName: "appeal-monthly-tracker-report.csv",
    endpointSuffix: "appeal-monthly-tracker",
    columns: [
      {
        title: "Level",
        field: "appealLevelLabel",
        sorter: "string",
        width: 150,
        visible: true,
        hozAlign: "left"
      },
      {
        title: "Claim Number",
        field: "clientClaimId",
        sorter: "string",
        width: 100,
        editable: false,
        visible: true
      },
      {
        title: "6D Case ID",
        field: "caseId",
        sorter: "number",
        width: 100,
        visible: true,
        hozAlign: "right"
      },
      {
        title: "Plan",
        field: "healthPlanName",
        sorter: "string",
        width: 200,
        visible: true
      },
      {
        title: "State",
        field: "providerState",
        sorter: "string",
        width: 100,
        visible: true
      },
      {
        title: "LOB",
        field: "lineOfBusiness",
        sorter: "number",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Par/Non-par",
        field: "providerPar",
        sorter: "number",
        width: 100,
        editable: false,
        visible: true
      },
      {
        title: "Pre/Post-pay",
        field: "prePostPay",
        sorter: "number",
        width: 100,
        editable: false,
        visible: true
      },
      {
        title: "Referral Date",
        field: "ibReceivedDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Patient Name",
        field: "patientName",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "TIN",
        field: "providerEIN",
        sorter: "number",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Facility Name",
        field: "primaryProviderName",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "DOS Start",
        field: "coverPeriodFromDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "DOS End",
        field: "coverPeriodThruDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 110,
        editable: false,
        visible: true
      },
      {
        title: "Client Member No",
        field: "clientMemberNumber",
        sorter: "string",
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Review Sent to Client",
        field: "reviewReportSentToClientDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 200,
        editable: false,
        visible: false
      },
      {
        title: "Appeal Rcvd",
        field: "appealReceivedDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Appeal Completed",
        field: "appealCompletedDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Date Sent to CnC",
        field: "cncsentDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Date Sent to Provider",
        field: "appealResponseSentToProviderDate",
        sorter: "date",
        sorterParams: {
          format: "MM/dd/yyyy"
        },
        width: 200,
        editable: false,
        visible: true
      },
      {
        title: "Original Balance Due",
        field: "preReviewPayable",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Adjusted Balance Due",
        field: "postReviewPayable",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Savings After Initial Review",
        field: "trueSavings",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Amount Appealed",
        field: "appealTotalCharges",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Appeal Adjustments",
        field: "appealAdjustments",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Appeal Payable",
        field: "postAppealPayable",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Appeal Savings",
        field: "appealSavings",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Payable Difference",
        field: "payableDifference",
        sorter: "string",
        width: 100,
        editable: false,
        bottomCalc: "sum",
        bottomCalcFormatter: "money",
        bottomCalcFormatterParams: {symbol: '$'},
        hozAlign: "right",
        formatter: "money",
        formatterParams: { symbol: '$' },
        headerWordWrap: true,
        visible: true
      },
      {
        title: "Notes",
        field: "claimNotes",
        width: 300,
        editable: false,
        visible: true
      }
    ],
    filters: [
      {
        label: "Select Client(s)",
        dashboardFilterParamType: "PRIMARY_CLIENTS",
        dataType: "primary_client",
        formControlName: "primaryClient",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Plan(s)",
        dashboardFilterParamType: "HEALTH_CARE_PLANS",
        dataType: "health_care_plan",
        formControlName: "healthCarePlan",
        defaultValue: "",
        required: true
      },
      {
        label: "Select Line of Business",
        dashboardFilterParamType: "LOB",
        dataType: "lob",
        formControlName: "lob",
        defaultValue: "",
        required: true
      },
      {
        label: "Claim Create From Date",
        dashboardFilterParamType: "FROM_DATE",
        dataType: "date",
        formControlName: "fromDate",
        defaultValue: firstDayOfMonth,
        required: true
      },
      {
        label: "Claim Create Thru Date",
        dashboardFilterParamType: "THRU_DATE",
        dataType: "date",
        formControlName: "thruDate",
        defaultValue: lastDayOfMonth,
        required: true
      },
      {
        label: "Select Case Type(s)",
        dashboardFilterParamType: "MI_CASE_TYPES",
        dataType: "mi_case_type",
        formControlName: "miCaseType",
        defaultValue: "",
        required: true
      },
    ]
  },

}

/*
  // Example Chart #1 - Monthly Claim Savings
  public barChartData: any = {
    datasets: [{
      label: "Monthly Claim Savings",
      data: [135000, 456000, 314000, 220000, 110000, 560000],
      backgroundColor: ["gray"],
    }],
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
  };
  public chartLabels: any;
  public barChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false
  };

  // Example Chart #2 - MI Case Completions
  public chart2Data: any = {
    datasets: [{
      label: "Monthly Claim Savings",
      data: [13, 25, 21, 32, 11, 46],
      backgroundColor: ["gray"],
    }],
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
  };
  public chart2Labels: any;
  public chart2Options: any = {
    responsive: true,
    maintainAspectRatio: false
  };
 */
